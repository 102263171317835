import { Button, ButtonContainer, Paragraph, SectionHeading } from "@vwfs-bronson/bronson-react";
import { CommonProductPage } from "@product-pages/components/common";
import { useTranslation } from "react-i18next";
import { getStorefrontData } from "@services/redux/features/storefront.redux";
import { connect } from "react-redux";
import { getScenario, isDealer, redirectMethod, redirectTo } from "@common/utils";
import { getToken, getTransactionId } from "@services/redux/features/transaction.redux";

interface ErrorDocumentsPagePropsRedux {
  storefrontData: any;
  transactionId?: string;
  token?: string;
}

export const ErrorDocumentsPage = ({ storefrontData, token, transactionId }: ErrorDocumentsPagePropsRedux) => {
  const { t } = useTranslation();
  const isDealerJourney = isDealer();
  const texts = t(`error:errorDocuments:${getScenario()}:texts`, { returnObjects: true }) as Array<string>;
  const dealerData = storefrontData?.dealerData;

  const tryAgain = () => {
    const url = new URL(window.location.href);
    return `${url.origin}/prefill?cid=${transactionId}&token=${token}`;
  };
  return (
    <>
      {isDealerJourney ? (
        <CommonProductPage>
          <SectionHeading level={3} smallSpacing={true} className="u-mb-large" center>
            {t(`error:errorDocuments:DEALER:title`)}
          </SectionHeading>
          {texts.map((text: string) => (
            <Paragraph className="u-mv-small">{text}</Paragraph>
          ))}
          <Paragraph className="u-mt-large"> {t(`error:errorDocuments:DEALER:bodyText`)}</Paragraph>
          <Paragraph>{t("error:errorDocuments:DEALER:financingText", { processID: storefrontData?.transaction?.acpProcessId ?? "-" })}</Paragraph>
          <Paragraph>{t("error:errorDocuments:DEALER:transactionText", { transactionID: transactionId ?? "-" })}</Paragraph>
          <ButtonContainer center className="u-mt-xlarge">
            <Button type="button" onClick={() => redirectTo(tryAgain())}>{t("error:errorOption:DEALER:buttonTryAgain")}</Button>
            <Button type="button" onClick={() => redirectMethod(storefrontData)}>
              {t(`error:errorDocuments:DEALER:buttonGoBack`, {
                originSystem: storefrontData?.transaction?.salesChannel?.originSystem,
              })}
            </Button>
          </ButtonContainer>{" "}
        </CommonProductPage>
      ) : (
        <CommonProductPage>
          <SectionHeading level={3} smallSpacing={true} className="u-mb-small" center>
            {t(`error:errorDocuments:CUSTOMER:title`)}
          </SectionHeading>
          {texts.map((text: string) => (
            <Paragraph className="u-mb-small">{text}</Paragraph>
          ))}
          {
            <div className="u-text-left u-mt-large">
              <Paragraph>{dealerData?.companyName}</Paragraph>
              <p>
                {dealerData?.addresses[0]?.street} {dealerData?.addresses[0]?.houseNumber}
              </p>
              <p>
                {dealerData?.addresses[0]?.zipCode} {dealerData?.addresses[0]?.city}
              </p>
              <p>
                {dealerData?.contactData?.telephoneCountryCode} {dealerData?.contactData?.telephoneNumber}
              </p>
              <p>{dealerData?.contactData?.emails[0]?.emailAddress}</p>
            </div>
          }
          <ButtonContainer className="u-mt-xlarge" center>
            <Button type="button" onClick={() => redirectTo(tryAgain())}>{t("error:errorOption:CUSTOMER:buttonTryAgain")}</Button>
            <Button type="button" onClick={() => redirectMethod(storefrontData)}>
              {t(`error:errorDocuments:CUSTOMER:buttonGoBack`, {
                originSystem: storefrontData?.transaction?.salesChannel?.originSystem,
              })}
            </Button>
          </ButtonContainer>{" "}
        </CommonProductPage>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    storefrontData: getStorefrontData(state),
    transactionId: getTransactionId(state),
    token: getToken(state),
  };
};
export default connect<{}, {}>(mapStateToProps, {})(ErrorDocumentsPage);
