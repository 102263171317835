import { useEffect } from "react";
import { Button, Layout, Paragraph, SectionHeading } from "@vwfs-bronson/bronson-react";
import { CommonProductPage } from "@product-pages/components/common";
import { useTranslation } from "react-i18next";
import { getStorefrontData } from "@services/redux/features/storefront.redux";
import { connect } from "react-redux";
import { trackPage } from "./tracking";
import { getScenario, isDealer, redirectMethod, redirectTo } from "@common/utils";
import { store } from "@services/redux";
import * as customFormActions from "../../../services/redux/features/custom.redux";
import { getToken, getTransactionId } from "@services/redux/features/transaction.redux";
export interface ErrorBasePageProps {
  onClick?: Function;
}

interface ErrorBasePagePropsRedux extends ErrorBasePageProps {
  storefrontData: any;
  transactionId?: string;
  token?: string;
}

const ErrorBasePage = ({ storefrontData, token, transactionId }: ErrorBasePagePropsRedux) => {
  const { t } = useTranslation();
  const isDealerJourney = isDealer();
  const texts = t(`error:errorOption:${getScenario()}:texts`, { returnObjects: true, defaultValue: t(`error:errorOption:default:texts`, { returnObjects: true }) }) as Array<string>;
  useEffect(() => {
    trackPage();
  }, []);

  const continueLater = async () => {
    store.dispatch(customFormActions.setShowContinueLater(true));
  };

  const tryAgain = () => {
    const url = new URL(window.location.href);
    return `${url.origin}/prefill?cid=${transactionId}&token=${token}`;
  };

  return (
    <>
      {!isDealerJourney ? (
        <CommonProductPage>
          <SectionHeading level={3} smallSpacing={true} className="u-mb-large" center>
            {t("error:errorOption:CUSTOMER:title")}
          </SectionHeading>
          {texts.map((text: string) => (
            <Paragraph>{text}</Paragraph>
          ))}
          <Layout center>
            <Layout.Item className="u-text-center">
              <Button className="u-mr" type="button" onClick={() => redirectTo(tryAgain())}>
                {t("error:errorOption:CUSTOMER:buttonTryAgain")}
              </Button>
              <Button
                type="button"
                onClick={() => {
                  continueLater();
                }}
              >
                {t("error:errorOption:CUSTOMER:buttonGoBack")}
              </Button>
            </Layout.Item>
          </Layout>
        </CommonProductPage>
      ) : (
        <CommonProductPage>
          <SectionHeading level={3} smallSpacing={true} className="u-mb-large" center>
            {t("error:errorOption:DEALER:title")}
          </SectionHeading>
          {texts.map((text: string, index: number) => (
            <Paragraph key={index}>{text}</Paragraph>
          ))}
          <Paragraph> {t("error:errorOption:DEALER:questionText")}</Paragraph>
          <Paragraph>{t("error:errorOption:DEALER:financingText", { processID: storefrontData?.transaction?.acpProcessId ?? "-" })}</Paragraph>
          <Paragraph>{t("error:errorOption:DEALER:transactionText", { transactionID: transactionId ?? "-" })}</Paragraph>
          {
            <Layout center>
              <Layout.Item className="u-text-center">
                <Button type="button" onClick={() => redirectTo(tryAgain())}>{t("error:errorOption:DEALER:buttonTryAgain")}</Button>
                <Button type="button" className="u-mr" onClick={() => redirectMethod(storefrontData)}>
                  {t("error:errorOption:DEALER:buttonGoBack", {
                    originSystem: storefrontData?.transaction?.salesChannel?.originSystem,
                  })}
                </Button>
              </Layout.Item>
            </Layout>
          }
        </CommonProductPage>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    storefrontData: getStorefrontData(state),
    transactionId: getTransactionId(state),
    token: getToken(state),
  };
};
export default connect<{}, {}, ErrorBasePageProps>(mapStateToProps, {})(ErrorBasePage);
