import * as React from "react";
import { ToastNotification, ToastNotifications, Logo, Header, ContextBar } from "@vwfs-bronson/bronson-react";
import "./PageHeader.css";
import { getKeycloakInitialized, getKeycloakIsLogged } from "@services/redux/features/keycloak.redux";
import { connect } from "react-redux";
import { logOut } from "@services/api/login";
import { useTranslation } from "react-i18next";
import useKeycloakConditonal from "@common/hooks/useKeycloakConditional";
import { Brand } from "@common/models/enums";

export interface PageHeaderProps {
  documentTitle?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
}

export interface PageHeaderReduxProps extends PageHeaderProps {
  isLoggedIn: boolean;
  keycloakInitialized: boolean;
}

const PageHeader = (props: PageHeaderReduxProps) => {
  const { t } = useTranslation();
  const [logoutSuccessful, setLogoutSuccessful] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const { documentTitle, isLoggedIn, keycloakInitialized, title } = props;
  const { keycloak } = useKeycloakConditonal(keycloakInitialized);

  const docTitle = documentTitle ? documentTitle : title;

  const brand = process.env.REACT_APP_BRAND;

  React.useEffect(() => {
    document.title = `${docTitle ? `${docTitle} - ` : ""}Volkswagen Bank GmbH`;
  }, [docTitle]);

  let removeNotification: any;
  const handleShowNotification = (value: boolean) => {
    setLogoutSuccessful(value);
    setShowNotification(true);
    removeNotification = setTimeout(() => setShowNotification(false), 5000);
  };

  const setLoggedOut = async (e: any) => {
    e.preventDefault();

    if (keycloak) {
      try {
        await logOut({
          token: keycloak.token,
          clientId: keycloak.clientId,
          clientSecret: keycloak.clientSecret,
          refreshToken: keycloak.refreshToken,
        });
        keycloak?.clearToken();
        handleShowNotification(true);
      } catch (error) {
        handleShowNotification(false);
      }
    }
  };

  return (
    <>
      {showNotification && (
        <ToastNotifications>
          <ToastNotification
            onClose={() => {
              setShowNotification(false);
              clearTimeout(removeNotification);
            }}
            {...(logoutSuccessful ? { success: true } : { error: true })}
          >
            {t("login:logoutSuccess")}
          </ToastNotification>
        </ToastNotifications>
      )}
      <Header
        testId="appHeader"
        staticHeader
        logo={
          <div style={{ display: "flex", maxHeight: "70px" }}>
            {brand === Brand.vwn ? <Logo custom height="40px" heightSmall="118px" src="https://cdn.bronson.vwfs.io/vw6/v/16.0.0/img/logo-commercial-vehicle-de.svg" /> : <Logo />}          </div>
        }
      >
        <ContextBar isContextBar>
          {(title || isLoggedIn) && (
            <ContextBar.SiteNav>
              <ContextBar.SiteNav.Item title noLink label={title as any}></ContextBar.SiteNav.Item>
            </ContextBar.SiteNav>
          )}
          {(title || isLoggedIn) && (
            <ContextBar.SiteNav>
              <ContextBar.SiteNav.Item noLink>
                {isLoggedIn && (
                  <a className="c-context-bar__link" href="#/" onClick={setLoggedOut} rel="noopener noreferrer">
                    <span>{t("login:logout")}</span>
                  </a>
                )}
              </ContextBar.SiteNav.Item>
            </ContextBar.SiteNav>
          )}
        </ContextBar>
      </Header>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: getKeycloakIsLogged(state),
    keycloakInitialized: getKeycloakInitialized(state),
  };
};
export default connect<{}, {}, PageHeaderProps>(mapStateToProps)(PageHeader);
