import { Button, ButtonContainer, Paragraph, SectionHeading } from "@vwfs-bronson/bronson-react";
import { CommonProductPage } from "@product-pages/components/common";
import { useTranslation } from "react-i18next";
import { getStorefrontData } from "@services/redux/features/storefront.redux";
import { connect } from "react-redux";
import { isOnline } from "@common/utils";
import { useNavigate } from "react-router-dom";
import { JourneyRoutes } from "@common/models/enums";
export interface ErrorBasePageProps {
  onClick?: Function;
}

interface ErrorBasePagePropsRedux extends ErrorBasePageProps {
  storefrontData: any;
}

const CalculationInvalid = ({ storefrontData }: ErrorBasePagePropsRedux) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSalesChannelTypeOnline = isOnline(storefrontData?.transaction?.salesChannel?.salesChannelType);
  const salesChannelType = isSalesChannelTypeOnline ? "online" : "default";
  const dealerData = storefrontData?.dealerData;

  const goToCalculationPage = () => {
    navigate(JourneyRoutes.CALCULATION);
  };

  return (
    <>
      <CommonProductPage pageWrapSize="xxsmall">
        <SectionHeading level={3} smallSpacing={true} className="u-mb-large" center>
          {t(`calculation:invalid:title`)}
        </SectionHeading>
        <Paragraph>{t(`calculation:invalid:${salesChannelType}:text`)}</Paragraph>
        {!isSalesChannelTypeOnline ? (
          <div className="u-mt">
            <Paragraph>{dealerData?.companyName}</Paragraph>
            <Paragraph>
              {dealerData?.addresses[0]?.street} {dealerData?.addresses[0]?.houseNumber}
              <br />
              {dealerData?.addresses[0]?.zipCode} {dealerData?.addresses[0]?.city}
            </Paragraph>
            <Paragraph>
              {dealerData?.contactData?.telephoneCountryCode} {dealerData?.contactData?.telephoneNumber}
              <br />
              {dealerData?.contactData?.emails[0]?.emailAddress}
            </Paragraph>
          </div>
        ) : (
          <ButtonContainer center className="u-mt-xlarge">
            <Button type="button" onClick={goToCalculationPage}>{t(`calculation:invalid:online:button`)}</Button>
          </ButtonContainer>
        )}
      </CommonProductPage>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    storefrontData: getStorefrontData(state),
  };
};
export default connect<{}, {}, ErrorBasePageProps>(mapStateToProps, {})(CalculationInvalid);
