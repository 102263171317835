import { Button, ButtonContainer, Paragraph, SectionHeading } from "@vwfs-bronson/bronson-react";
import { CommonProductPage } from "@product-pages/components/common";
import { useTranslation } from "react-i18next";
import { getStorefrontData } from "@services/redux/features/storefront.redux";
import { connect } from "react-redux";
import { getScenario, isDealer, isDistanceSales, isPointOfSales, redirectMethod } from "@common/utils";
interface ErrorExpiredPagePropsRedux {
  storefrontData: any;
}
export const ExpiredLinkPage: React.FC<any> = ({ storefrontData }: ErrorExpiredPagePropsRedux) => {
  const { t } = useTranslation();
  const isDealerJourney = isDealer();
  const texts = t(`expired:${getScenario()}:texts`, { returnObjects: true, defaultValue: t(`expired:default:texts`, { returnObjects: true }) }) as Array<string>;
  const dealerData = storefrontData?.dealerData;
  const isDistanceSalesOrPointOfSale = isDistanceSales(storefrontData?.transaction?.salesChannel?.salesChannelType) || isPointOfSales(storefrontData?.transaction?.salesChannel?.salesChannelType);
  return (
    <>
      {!isDealerJourney ? (
        <CommonProductPage>
          <SectionHeading level={3} smallSpacing={true} className="u-mb-large" center>
            {t("expired:CUSTOMER:title")}
          </SectionHeading>
          {texts.map((text: string) => (
            <Paragraph>{text}</Paragraph>
          ))}
          {isDistanceSalesOrPointOfSale && (
            <div className="u-text-left">
              <Paragraph>{dealerData?.companyName}</Paragraph>
              <p>
                {dealerData?.addresses[0]?.street} {dealerData?.addresses[0]?.houseNumber}
              </p>
              <p>
                {dealerData?.addresses[0]?.zipCode} {dealerData?.addresses[0]?.city}
              </p>
              <p>
                {dealerData?.contactData?.telephoneCountryCode} {dealerData?.contactData?.telephoneNumber}
                {dealerData?.contactData?.emails[0]?.emailAddress}
              </p>
            </div>
          )}
        </CommonProductPage>
      ) : (
        <CommonProductPage>
          <SectionHeading level={3} smallSpacing={true} className="u-mb-large" center>
            {t("expired:DEALER:title")}
          </SectionHeading>
          {texts.map((text: string) => (
            <Paragraph>{text}</Paragraph>
          ))}
          <ButtonContainer center className="u-mt-xlarge">
            <Button type="button" onClick={() => redirectMethod(storefrontData)}>
              {t("expired:DEALER:button", {
                originSystem: storefrontData?.transaction?.salesChannel?.originSystem,
              })}
            </Button>
          </ButtonContainer>
        </CommonProductPage>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    storefrontData: getStorefrontData(state),
  };
};
export default connect<{}, {}, ErrorExpiredPagePropsRedux>(mapStateToProps, {})(ExpiredLinkPage);
