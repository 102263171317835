interface Props {
  id: string;
}

export const SkipLink: React.FC<Props> = ({ id }) => {
  const handleSkipLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const mainContent = document.getElementById(id);
    if (mainContent) {
      mainContent.scrollIntoView({ behavior: "smooth", block: "start" });
      mainContent.setAttribute("tabindex", "-1");
      mainContent.focus();
      mainContent.removeAttribute("tabindex");
    }
  };

  return (
    <a href={`#${id}`} onClick={handleSkipLink} className="u-visually-hidden">
      Skip Link
    </a>
  );
};
