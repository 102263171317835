import { Button, ButtonContainer, Paragraph, SectionHeading } from "@vwfs-bronson/bronson-react";
import { CommonProductPage } from "@product-pages/components/common";
import { useTranslation } from "react-i18next";
import { getScenario, redirectMethod } from "@common/utils";
import { getStorefrontData } from "@services/redux/features/storefront.redux";
import { connect } from "react-redux";

interface ErrorAccessDeniedPropsRedux {
  storefrontData: any;
}
export const ErrorAccessDeniedPage: React.FC<any> = ({ storefrontData }: ErrorAccessDeniedPropsRedux) => {
  const { t } = useTranslation();
  const scenario = getScenario();
  const texts = t(`denied:${scenario}:texts`, { returnObjects: true, defaultValue: t(`denied:default:texts`, { returnObjects: true }) }) as Array<string>;
  return (
    <CommonProductPage>
      <SectionHeading level={3} smallSpacing={true} className="u-mb-large" center>
        {t(`denied:${scenario}:title`, { defaultValue: t(`denied:default:title`) })}
      </SectionHeading>
      {texts.map((text: string) => (
        <Paragraph>{text}</Paragraph>
      ))}
      <ButtonContainer right className="u-mt-xlarge">
        <Button type="button" onClick={() => redirectMethod(storefrontData)}>
          {t(`denied:${scenario}:button`, {
            defaultValue: t(`denied:default:button`),
            originSystem: storefrontData?.transaction?.salesChannel?.originSystem,
          })}
        </Button>
      </ButtonContainer>
    </CommonProductPage>
  );
};
const mapStateToProps = (state: any) => {
  return {
    storefrontData: getStorefrontData(state),
  };
};
export default connect<{}, {}, ErrorAccessDeniedPropsRedux>(mapStateToProps, {})(ErrorAccessDeniedPage);
