import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Callout, ContentSection, Paragraph, Layout } from "@vwfs-bronson/bronson-react";
import imageCHI from "@resources/images/imagesCHI/CHI--bluelabel.png";

export type CHIsectionProps = {
  onConfirm: any;
  modal?: boolean;
};

export const CHIsection: FunctionComponent<CHIsectionProps> = ({ onConfirm, modal = false }) => {
  const { t } = useTranslation();

  return (
    <ContentSection className="u-p-none">
      <Callout
        media={{
          alt: "Customer Happiness Index icon",
          sizes: "30%",
          src: imageCHI,
        }}
        title={t(`chi${modal ? ":modal" : ""}:title`)}
      >
        <Paragraph>{t(`chi${modal ? ":modal" : ""}:text`)}</Paragraph>
        <Layout className="u-mt" center>
          <Layout.Item className="u-text-center">
            <Button type="button" onClick={onConfirm}>{t(`chi${modal ? ":modal" : ""}:button`)}</Button>
          </Layout.Item>
        </Layout>
      </Callout>
    </ContentSection>
  );
};
